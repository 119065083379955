'use strict';

const FormInput =  React.lazy(() => import( /* webpackChunkName: "FormInput" */ '../components/FormInput'));

class PdfModal extends React.Component {
    constructor(props) {
        super(props);
        this.initState = {
            type: null,
            url: null,
            price: '',
            comment: '',
        }
        this.state = this.initState;
    }

    componentDidMount() {
        listenEvent('react.editPdf', data => {
            if(data === null) {
                this.setState(this.initState);
            } else {
                this.setState({
                    type: data.type,
                    url: data.url,
                });
            }
        })
    }

    handleSubmit() {
        $('#pdfModalForm').submit();
        $.fancybox.close();
    }

    render() {
        const {url, type, price, comment} = this.state;

        let title = tMsg('Предложение для клиента');
        let text = tMsg('Вы можете редактировать стоимость и комментарии в PDF-предложении для вашего клиента');
        if(type === 'confirm') {
            title = tMsg('Подтверждение для клиента');
            text = tMsg('Вы можете редактировать стоимость и комментарии в PDF-подтверждении для вашего клиента');
        }
        if(type === 'details') {
            title = tMsg('Детали по рейсу для клиента');
            text = tMsg('Вы можете редактировать комментарии в PDF-деталях по рейсу для вашего клиента');
        }

        return <div>
            <form id="pdfModalForm" action={url} method="post" target="_blank">
                <input type="hidden" name="price" value={price}/>
                <input type="hidden" name="comment" value={comment}/>
            </form>

            <div className="modal-title text-center">
                {title}
            </div>
            <div className="modal-text">
                <p>{text}</p>
            </div>
            <div className="offer-form">
                <div className="offer-form__row">
                    <React.Suspense>
                        <FormInput
                            className={'plane-input'}
                            label={tMsg('Стоимость')}
                            value={price}
                            onChange={price => this.setState({
                                                   price: price.replace(/[^\d]*/g, '')
                                                   })}
                        />
                    </React.Suspense>
                </div>
                <div className="offer-form__row">
                    <React.Suspense>
                        <FormInput
                            type={'textarea'}
                            className={'offer-form__textarea'}
                            label={tMsg('Комментарий')}
                            value={comment}
                            multiLine={true}
                            onChange={comment => this.setState({comment})}
                        />
                    </React.Suspense>
                </div>
            </div>
            <div className="modal-btn">
                <button className="button button-base" onClick={this.handleSubmit}>{tMsg('Скачать PDF')}</button>
            </div>
        </div>
    }
}

const domContainer = document.querySelector('#reactPdfModal');
const root = ReactDOM.createRoot(domContainer);
root.render(React.createElement(PdfModal));
